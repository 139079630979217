import { DateTime, Duration } from "luxon";
import camelCase from "lodash-es/camelCase";
import { stemmer } from "stemmer";

export function classNames(cls, mods, additional = []) {
  return [
    cls,
    ...additional.filter((v) => v !== 'undefined' && !!v),
    ...Object.entries(mods)
      .filter(([, value]) => Boolean(value))
      .map(([className]) => className),
  ].join(' ');
}


export function getImageUrl(imageUrl, options = {}) {
  if (imageUrl) {
    const url = new URL(imageUrl);
    url.hostname = "images.mavii.com";

    Object.keys(options).forEach((key) => {
      url.searchParams.delete(key);
      url.searchParams.append(key, options[key]);
    });

    return url.toString();
  }
}

export function formatDate(dateString, opts = { style: "long" }) {
  if (dateString) {
    const date = DateTime.fromISO(dateString);
    let formattedDate;

    if (date > DateTime.now().minus({ days: 30 })) {
      formattedDate = date.toRelative(opts);

      if (opts.style === "short") {
        // Get the beginning of the string 4 hours ago -> 4h
        formattedDate = formattedDate.replace(/^(\d+) (\w).*?$/, "$1$2");
      }
    } else {
      formattedDate = date.toLocaleString(DateTime.DATE_MED);
    }

    return formattedDate;
  }
}

export function formatDuration(durationString) {
  if (durationString) {
    const duration = Duration.fromISO(durationString);

    if (duration.hours > 0) {
      return duration.toFormat("hh:mm:ss");
    } else {
      return duration.toFormat("mm:ss");
    }
  }
}

export function formatNumber(number, maximumFractionDigits = 0) {
  return number.toLocaleString(undefined, {
    maximumFractionDigits: maximumFractionDigits
  });
}

export function getOrientation({ width, height }) {
  if (width && height) {
    return width < height ? "portrait" : "landscape";
  }
}

export function getChromeDisplayUrl(url) {
  if (url) {
    const { hostname, pathname } = new URL(url);

    return hostname.replace(/^www\./, "") + pathname.replace(/\/$/, "");
  }
}

export function getHostname(urlString, removeWww = true) {
  if (urlString) {
    const { hostname } = new URL(urlString);

    if (removeWww) {
      return hostname.replace(/^www\./, "");
    } else {
      return hostname;
    }
  }
}

export function toClassName(input) {
  if (input) {
    return camelCase(input);
  } else {
    return "";
  }
}

export function arrayToClassNames(array) {
  if (array?.length) {
    return array.map((input) => toClassName(input)).join(" ");
  } else {
    return "";
  }
}

export function highlightNewWords(query, newQuery) {
  if (query && newQuery) {
    const queryWords = query.split(/\s+/).map((qWord) => stemmer(qWord.toLowerCase()));
    const newQueryWords = newQuery.split(/\s+/);

    const highlightedQuery = newQueryWords
      .map((word) => {
        const lowerWord = stemmer(word.toLowerCase());

        if (queryWords.includes(lowerWord)) {
          return word;
        } else {
          return `<b>${word}</b>`;
        }
      })
      .join(" ");

    return highlightedQuery;
  } else {
    return newQuery;
  }
}

export function getLanguageName(abbreviation) {
  const languageNames = new Intl.DisplayNames(["en"], { type: "language" });

  return languageNames.of(abbreviation);
}

export function getSearchUrl(path, params) {
  const url = new URL(path, "https://www.mavii.com");

  // Update the query string
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined) {
      url.searchParams.delete(key);
    } else {
      url.searchParams.set(key, params[key]);
    }
  });

  return url.pathname + url.search;
}

export function isRootPath(urlString) {
  try {
    const { pathname } = new URL(urlString);
    return pathname === "/";
  } catch (e) {
    return false;
  }
}

export function getSearchStats(data) {
  let stats = {};

  if (data?.layout) {
    // This should always be present but you never know with bing
    const query = data.queryContext?.originalQuery || "";

    stats.query = {
      length: query.length,
      wordCount: query.trim().split(/\s+/).length,
      // TODO: too sensitive?
      // adultIntent: !!data.queryContext?.adultIntent,
      spellingCorrected: !!data.queryContext?.alteredQuery
    };
    // TODO: This is error prone
    stats.hasNavigationalResult = !!data?.layout?.mainline?.[0]?.items?.[0]?.isNavigational;
    stats.sectionTypes = Object.keys(data.layout);
    stats.totalServerTime = data.totalTime;

    let answerTypes = [];

    for (const section of Object.values(data.layout)) {
      for (const resultList of section) {
        if (resultList.answerType && !answerTypes.includes(resultList.answerType)) {
          answerTypes.push(resultList.answerType);
        }
      }
    }

    stats.answerTypes = answerTypes;
  }

  return stats;
}